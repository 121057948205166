<div
  class="container"
  [style.height.px]="size"
  [style.width.px]="size"
  [class.selected]="selected"
  [class.create-event-view]="createEventView"
  [class.shadow]="displayShadow"
>
  <img [src]="template.thumbnailUrl" alt="Template Thumbnail" class="image" />
  <div class="middle">
    @if (allowActions) {
      @if (selectMode) {
        @if (!selected) {
          <button
            type="button"
            class="btn btn-action"
            (click)="chooseTemplate()"
            [class.spinner]="eventLoading"
            [disabled]="eventLoading"
          >
            {{ "APP.SELECT" | translate }}
          </button>
        }
      } @else {
        <button
          type="button"
          class="btn btn-primary-outline"
          [class.event-btn-outline]='eventColors'
          (click)="previewTemplate()"
        >
          {{ "APP.PREVIEW" | translate }}
        </button>
        @if (!previewOnly) {
          <button
            type="button"
            class="btn btn-primary-party-page"
            [class.event-btn]='eventColors'
            (click)="chooseTemplate()"
            [class.spinner]="eventLoading"
            [disabled]="eventLoading"
          >
            {{ "APP.CREATE_EVENT.CHOOSE_THIS_TEMPLATE" | translate }}
          </button>
        }
      }
    }
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Event } from '@models/events/event.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalEventStore {
  private _event: BehaviorSubject<Event | undefined> = new BehaviorSubject<
    Event | undefined
  >(undefined);

  public readonly event: Observable<Event | undefined> =
    this._event.asObservable();

  constructor() {}

  setEvent(event: Event) {
    this._event.next(event);
  }

  resetEvent() {
    this._event.next(undefined);
  }
}
